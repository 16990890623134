// i18next-extract-mark-ns-start bizum-for-woocommerce
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import woocommerce_bizum from 'images/woocommerce_bizum.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';

import {InternalPageLink, PaymentsOrchestrationLink} from 'components/links/Pages';
import {BlogLink} from 'components/links/Blog';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="bizum-for-woocommerce"
        title={t('Get Bizum for WooCommerce | MONEI')}
        description={t(
          'Add Bizum to your WooCommerce store to let your customers pay with one of the most popular mobile payment apps in Spain. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Quickly and easily add Bizum to your WooCommerce store</Trans>
              </SectionHeader>
              <Trans parent="p">
                Bizum is a popular peer-to-peer mobile payment app that has over a dozen million
                active daily users. But it’s not only used by individuals to send and receive money.
                More than 11,000 online stores already accept Bizum e-commerce payments. Keep up
                with the competition by adding Bizum to your WooCommerce store.{' '}
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={woocommerce_bizum} alt="Woocommerce bizum" title="Woocommerce bizum" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept Bizum payments and many other WooCommerce payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding Bizum to your WooCommerce store will help you reach more customers and
                increase conversions. But online shoppers look for many payment options to choose
                from. That’s why we help you accept and manage the widest range of{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                in a single dashboard.
              </Trans>
              <Trans parent="p">
                Once you’ve activated your account, you’ll be able to accept{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum payments</InternalPageLink>{' '}
                along with{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , digital wallets like{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , and more <BlogLink slug="local-payment-methods">local payment methods.</BlogLink>{' '}
                By offering{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                you'll improve the checkout experience and turn more website visitors into loyal
                customers.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Boost sales with payments orchestration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Improve your sales revenue by providing a{' '}
                <BlogLink slug="frictionless-payments">frictionless</BlogLink> payment experience
                that leads to more approved payments. With{' '}
                <PaymentsOrchestrationLink>payments orchestration</PaymentsOrchestrationLink>, you
                can choose payment processing rules. This way, if your default payment processor is
                experiencing downtime we’ll send the transaction to another one. Your customers will
                experience fewer false{' '}
                <BlogLink slug="online-payment-failure-message">payment failure messages</BlogLink>,
                and you’ll benefit from a higher conversion rate.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the lowest WooCommerce payment gateway pricing</Trans>
              </SectionHeader>
              <Trans parent="p">
                Don’t struggle with high transaction fees. As you sell more, your transaction fees
                will decrease in real-time thanks to our dynamic pricing model. We want to help you
                grow your e-commerce business faster, that’s why we’re the only payment gateway to
                offer scaled platform usage rates.
              </Trans>
              <Trans parent="p">
                Enjoy the benefits of using the most flexible{' '}
                <Link to="/woocommerce-payment-gateway/">WooCommerce payment gateway</Link> —{' '}
                <Link to="/pricing/">view pricing here</Link>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>
                Integrate your WooCommerce store with MONEI to start accepting Bizum payments
              </Trans>
            </SectionHeader>
            <Trans parent="p">
              Quickly and easily connect your WooCommerce store with MONEI to start accepting Bizum
              (and more payment methods) today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/sections/360005119097-Bizum">
                  Configure Bizum payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/woocommerce/">
                  WooCommerce plugin guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "bizum-for-woocommerce"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
